import styles from './Report.module.css'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useTranslation } from 'react-i18next'
import ButtonUp from '../components/ButtonUp'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'

function Report() {
    const { t } = useTranslation()
    return (
        <div className={styles.container}>
            <ScrollToTopOnMount/>
            <Navbar />
            <div className={styles.reportContainer}>
                <h2>{t('report-heading')}</h2>
                <img src={require('../assets/report.jpg')} alt='report'></img>
            </div>
            <Footer/>
            <ButtonUp/>
        </div>
    )
}

export default Report