import Navbar from '../components/Navbar'
import Dish from '../components/Dish'
import Footer from '../components/Footer'
import { useTranslation } from 'react-i18next'
import styles from './Dishes.module.css'
import { ReactComponent as HeartIcon } from '../assets/heart-icon.svg'
import { ReactComponent as BroccoliIcon } from '../assets/broccoli-icon.svg'
import { ReactComponent as FridgeIcon } from '../assets/fridge-icon.svg'
import { ReactComponent as DessertIcon } from '../assets/dessert-icon.svg'
import { dishes } from '../store/dishes';
import ButtonUp from '../components/ButtonUp'
import { useLocation } from 'react-router-dom'
import { useEffect, useRef } from 'react'

function DishesCol(props) {
  const { dishes } = props;
  return (
    <>
    {
      dishes.map(dish => {
        return (
          <Dish dish={dish} key={dish.id} />
        )
      })
    }
    </>
  );
}

function Dishes() {
  const { t } = useTranslation()
  const location = useLocation();
  const { hash } = location;
  const dishesCategory = hash.substring(1);
  // links like http://localhost:3000/dishes#frozenproducts
  const categories = ['firstcources', 'salads', 'frozenproducts', 'desserts'];
  const index = categories.indexOf(dishesCategory);
  const refs = [useRef(), useRef(), useRef(), useRef()];
  useEffect(() => {
    let timer;
    if (index >= 0)
      timer = setTimeout(() => {
        refs[index].current?.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    else
      window.scrollTo(0, 0);
    return () => clearTimeout(timer);
  }, [index, refs])
  return (
    <div className={styles.pageContainer}>
        <Navbar />
        <div className={styles.header}>
          <h1 className={styles.dishesHeading}>{t('dishes-header-title')}</h1>
          <h1 className={styles.brandHeading}>Middag På Ukrainsk</h1>
          <p>{t('dishes-header-description')}</p>
        </div>
        <div ref={refs[0]} className={styles.groupContainer}>
          <p className={styles.slogan}>{t('dishes-slogan')}</p>
          <HeartIcon className={styles.heartIcon}/>
          <h2 className={styles.groupTitle}>{t('dishes-group-1')}</h2>
        </div>
        <div className={styles.firstCourses}>
          <DishesCol dishes={dishes.firstCourses} />
        </div>
        <div ref={refs[1]} className={styles.groupContainer}>
          <BroccoliIcon className={styles.broccoliIcon}/>
          <h2 className={styles.groupTitle}>{t('dishes-group-2')}</h2>
        </div>
        <div className={styles.salads}>
          <DishesCol dishes={dishes.salads} />
        </div>
        <div ref={refs[2]} className={styles.groupContainer}>
          <FridgeIcon className={styles.fridgeIcon}/>
          <h2 className={styles.groupTitle}>{t('dishes-group-3')}</h2>
        </div>
        <div className={styles.frozenProducts}>
          <DishesCol dishes={dishes.frozenProducts} />
        </div>
        <div ref={refs[3]} className={styles.groupContainer}>
          <DessertIcon className={styles.dessertIcon}/>
          <h2 className={styles.groupTitle}>{t('dishes-group-4')}</h2>
        </div>
        <div className={styles.desserts}>
          <DishesCol dishes={dishes.desserts} />
        </div>
        <ButtonUp />
        <Footer />
    </div>
  )
}

export default Dishes
