export const dishes = {
  firstCourses: [
    {
      id: "borsch",
      title: {
        ua: "УКРАЇНСЬКИЙ БОРЩ",
        dk: "UKRAINSK BORSCH"
      },
      description: {
        ua: "Традиційна перша страва, яка засмакує усім! Найкраще поєднується з в сметанкою, чорним хлібом, цибулькою та шматочком сала.",
        dk: "Ukrainsk borsch er stoltheden af ukrainsk køkken. Utroligt duftende, tyk, rig, krydret og umulig at velsmagende. Når du har prøvet det, bliver du endelig forelsket og uigenkaldeligt!"
      },
      unit: {
        ua: "ЗА ЛІТР",
        dk: "PR 1 L."
      },
      price: 150,
    },
    {
      id: "solyanka",
      title: {
        ua: "СОЛЯНКА",
        dk: "SOLYANKA"
      },
      description: {
        ua: 'Збірна тільки найкращих ковбасок, сосисок, шинки, або ж бекону. В поєднанні з оливками та лимончиком створюють неймовірно пікантий смак.',
        dk: "Kød solyanka fremstilles ved hjælp af forskellige slags kød (og nogle gange - færdige pølser). Tilsæt også og syltede: syltede agurker, surkål, kapers, oliven, citron, syltede eller syltede svampe, agurkindel."
      },
      unit: {
        ua: "ЗА ЛІТР",
        dk: "PR 1 L.",
      },
      price: 150,
    },
    {
      id: "gulyash",
      title: {
        ua: "ГУЛЯШ НАВАРИСТИЙ",
        dk: "GOULASH"
      },
      description: {
        ua: 'Ідеально пасує як на перші страви, так і на друге. М‘ясистий, соковитий, наваристий, свіженький гуляш - саме те, що потрібно.',
        dk: "Den suppe har mange navne, og som det er tilfældet med rigtig mange klassiske retter, også mange opskrifter.Goulash laver, som laves med paprika, vel-simreret oksekød og en masse løg.",
      },
      unit: {
        ua: 'ЗА ЛІТР',
        dk: "PR 1 L.",
      },
      price: 165,
    },
    {
      id: "salmon-soup",
      title: {
        ua: "СУП З ЛОСОСЕМ І ВЕРШКАМИ",
        dk: "FISKESUPPE MED LAKS",
      },
      description: {
        ua: "Витончений і ніжний рибний смак у лаконічкому поєднанні лосося та вершків.",
        dk: "Hvis spørgsmålet om aftenmenuen ikke handler om fordele og lavt kaloriindhold i skålen, skal du så sikkert vælge til fordel for laks med fløde i sammensætningen af mørbradssuppe. Utroligt lækker parabol vil ikke forlade ligeglade fiskelskere.",
      },
      unit: {
        ua: "ЗА ЛІТР",
        dk: "PR 1 L.",
      },
      price: 160,
    },
    // {
    //   id: "sea-soup",
    //   title: {
    //     ua: "СУП МОРСЬКИЙ КОКТЕЙЛЬ",
    //     dk: "SEA COCKTAIL SUPPE",
    //   },
    //   description: {
    //     ua: "Суп з відбірних морепродуктів найкраще підійде для холодних романтичних вечорів.",
    //     dk: "Bedøvelse suppe fra en havcocktail med fløde vil blive et ornament ikke kun af et afslappet bord, men også ret egnet til arkivering på en middagsselskab. En simpel men yderst duftende skål vil ikke forlade nogen ligeglade.",
    //   },
    //   unit: {
    //     ua: "ЗА ЛІТР",
    //     dk: "PR 1 L.",
    //   },
    //   price: 170,
    // },
    {
      id: "harcho",
      title: {
        ua: "ХАРЧО",
        dk: "HARCHO",
      },
      description: {
        ua:  "Суп походить з Грузії, але його дуже полюблять і в Україні. В міру гострий, ароматний та наваристий.",
        dk: "Under ordet HARCHO i georgisk køkken skjuler tykt, svejsesuppe med kød, ris, overflod af grønne områder og krydderier, med valnødder og forfriskende frugtssyre. Denne skål er så tilfredsstillende, at den erstatter det første og andet.",
      },
      unit: {
        ua: "ЗА ЛІТР",
        dk: "PR 1 L."
      },
      price: 150,
    }
  ],
  salads: [
    {
      id: "olivye",
      title: {
        ua: "ОЛІВ'Є",
        dk: "OLIVIER SALAT",
      },
      description: {
        ua: "Традиційна новорічна і післяноворічна усім відома страва - олів‘є. Його можна їсти у новорічну ніч, і на сніданок після свят, і на обід, і на вечерю.",
        dk: "Olivier salat har normalt ærter, kartofler, gulerødder, løg, kød, pickles og æg, alt sammen blandet med mayo. Kødet der bruges er enten bologna, kylling eller skinke.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 150,
    },
    {
      id: "shuba",
      title: {
        ua: "ШУБА",
        dk: "SILD UNDER EN PELSFRAKKE SALAT",
      },
      description: {
        ua: "Дрібно нарізане філе оселедця і накладене на шар картоплі, поступово накривається шарами подрібненої цибулі, яєць, моркви та червоного буряка.",
        dk: "Meget velsmagende og festlig salat. Sild, løg, kartofler, gulerødder er delt i lag som en kage. Top med revet rødbeder og mayonnaise. Salat er meget populær i Ukraine.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 150,
    },
    // {
    //   id: "cesar",
    //   title: {
    //     ua: "ЦЕЗАР",
    //     dk: "CÆSARSALAT",
    //   },
    //   description: {
    //     ua: "Цезар – коли не знаєш що замовити на обід чи вечерю, але точно знаєш, що хочеться чогось неймовірно смачного.",
    //     dk: "Cæsarsalat består af sprød salat, kylling - eller perlehøne, cripsy, gyldenstegte brødcroutoner og en velsmagende dressing og parmesanost. En salat de fleste kan lide!",
    //   },
    //   unit: {
    //     ua: "ЗА 1 КГ.",
    //     dk: "PR 1 KG.",
    //   },
    //   price: 150,
    // },
    {
      id: "crab-sticks",
      title: {
        ua: "З КРАБОВИХ ПАЛИЧОК",
        dk: "KRABBEN SALAT PÅ UKRAINSK",
      },
      description: {
        ua: "До складу входять: крабові палички, помідори чері, сир, зелень та часник. Усе це заправлене майонезом. Смакота ще та.",
        dk: "Den ukrainske version af salaten inkluderer krabbestænger, cherrytomater, salat, krydderurter, hvidløg, mayonnaise. Salaten har en meget rig smag.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 150,
    },
    {
      id: "chicken-pineapple",
      title: {
        ua: "З КУРКОЮ ТА АНАНАСАМИ",
        dk: "KYLLINGESALAT MED ANANAS",
      },
      description: {
        ua: "До складу входять: куряча грудинка, ананас, кукурудза, виноград, твердий сир, часник та зелень. Усе це заправлене майонезом. Дуже популярний салат на свята.",
        dk: "Udover kylling er der i salaten ananas, vindruer, ost, hvidløg og krydderurter – alt dette er krydret med mayonnaise. en favoritsalat af mange ukrainere.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 150,
    },
    {
      id: "broccoli",
      title: {
        ua: "З БРОКОЛІ",
        dk: "BROCCOLI SALAT",
      },
      description: {
        ua: "До складу входять: броколі, арахіс, родзинки, насіння гарбуза і соняшника та бекон. Усе заправлено йогуртом. Дуже смачно і корисно.",
        dk: "Broccolisalat med bacon, solsikkekerner og rosiner er en skøn kombination. Den klassiske broccolisalat er dejlig i sig selv, og kan også bruges som lækkert tilbehør til forskellige retter.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 150,
    },
    {
      id: "tuna",
      title: {
        ua: "Салат з тунця",
        dk: "Tunsalat",
      },
      description: {
        ua: "До складу входять: варена картопля, морква, яйця, тунець, сир, зелень та майонез.",
        dk: "Kogte kartofler, gulerødder, æg, tun, ost, grønt, mayonnaise.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 150,
    },
    {
      id: "georgian",
      title: {
        ua: 'Салат "Грузинський"',
        dk: '"Georgisk" salat',
      },
      description: {
        ua: "До складу входять: квасоля, перець болгарський, фіолетова цибуля маринована, варена яловичина, зелень та олія.",
        dk: "Bønner, peberfrugt, syltede lilla løg, kogt oksekød, grønt + olie.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 150,
    },
    {
      id: "sea",
      title: {
        ua: 'Салат "Морський"',
        dk: '"Havs" salat',
      },
      description: {
        ua: "До складу входять: рис, тунець, огірок, яйця, крабові палички, сир, майонез та зелень.",
        dk: "Ris, tun, agurk, æg, krabbestænger, ost, mayonnaise, grønt.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 150,
    }
  ],
  frozenProducts: [
    {
      id: "golubci",
      title: {
        ua: "ГОЛУБЦІ",
        dk: "FYLDT KÅL (RISFYLD OG SVINEKØD)",
      },
      description: {
        ua: "Одна з найкращих страв української кухні. Неймовірне поєднання рису з м‘ясом у листах тушеної капусти. Смачно та корисно.",
        dk: "En af de bedste retter i det ukrainske køkken. Utrolig kombination af ris med kød i stuvede kålblade.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 100,
    },
    {
      id: "chebureky",
      title: {
        ua: "ЧЕБУРЕКИ",
        dk: "CHEBUREKS MED HAKKKET SVINEKØD",
      },
      description: {
        ua: "Домашні хрусткі чебуреки зі свинним фаршем і соковитою цибулькою. Приготованні з любов'ю. Пальчики оближеш.",
        dk: "Hjemmelavede sprøde chebureks med hakket svinekød og saftige løg. Tilberedt med kærlighed. Du vil slikke fingrene.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 100,
    },
    {
      id: "pelmeni",
      title: {
        ua: "ПЕЛЬМЕНІ",
        dk: "DUMPLINGS MED HAKKET SVINEKØD",
      },
      description: {
        ua: "Пельмені – традиційна та улюблена страва у багатьох сім'ях. Наші домашні пельмені зі свининою сподобаються усій родині.",
        dk: "Dumplings er en traditionel og yndlingsret i mange familier. Hele familien vil kunne lide vores hjemmelavede svineboller.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 100,
    },
    {
      id: "curd-raisins-pancakes",
      title: {
        ua: "МЛИНЦІ З СИРОМ І РОДЗИНКАМИ",
        dk: "PANDEKAGER MED OST OG ROSINER",
      },
      description: {
        ua: "Улюблений сніданок українців. Домашній кисломолочний сир з родзинками в свіженьких млинцях - саме те, що потрібно для заряду бадьорості на цілий день.",
        dk: "Ukrainernes yndlingsmorgenmad. Hjemmelavet surmælksost med rosiner i friske pandekager - lige hvad du skal bruge for at lade batterierne op til hele dagen.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 100,
    },
    {
      id: "cheese-mushrooms-pancakes",
      title: {
        ua: "МЛИНЦІ З СИРОМ І ГРИБАМИ",
        dk: "PANDEKAGER MED OST OG SVAMPE",
      },
      description: {
        ua: "Домашні, смачні та дуже поживні млинці з сиром та грибами. Які заряджають енергією на цілий день однозначно сподобаються тобі та усій родині.",
        dk: "Pandekaker med ost og svampe. Hjemmelavede, lækre og meget nærende pandekager med ost og svampe. Opladning af energi til hele dagen.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 100,
    },
    {
      id: "pork-pancakes",
      title: {
        ua: "МЛИНЦІ ЗІ СВИНИНОЮ",
        dk: "PANDEKAGER MED SVINEKØD",
      },
      description: {
        ua: "Смачні, ситні та соковиті млинці зі свининою. Найкраще пасують до нашого борщику або бульйончику. Ну дуже поживно та смачно.",
        dk: "Lækre, solide og saftige pandekager med svinekød. Passer bedst til vores borscht eller bouillon. Meget nærende og lækkert. Du vil helt sikkert kunne lide det.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 100,
    },
    {
      id: "buckwheat-liver-pancakes",
      title: {
        ua: "МЛИНЦІ З ГРЕЧКОЮ ТА ПЕЧІНКОЮ",
        dk: "PANDEKAGER MED BOGHVEDE OG LEVER",
      },
      description: {
        ua: "Це поєднання смаку і харчових користей. Пухкі млинці, наповнені смаженою гречкою та ніжною печінкою, створюють неперевершений смаковий дует. Ідеальний вибір.",
        dk: "Det er en kombination af smag og ernæringsmæssige fordele. Fluffy pandekager fyldt med stegt boghvede og mør lever skaber en uovertruffen smagsduet. Perfekt valg.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 100,
    },
    {
      id: "chicken-cheese-pancakes",
      title: {
        ua: "МЛИНЦІ З КУРКОЮ ТА СИРОМ",
        dk: "PANDEKAGER MED KYLLING OG OST",
      },
      description: {
        ua: "Це смачна комбінація ніжних млинців, соковитого курячого м'яса і топленого сиру. Ситне задоволення для будь-якого часу дня.",
        dk: "Dette er en kombination af bundpandekager, hakket kyllingekød og smeltet ost. Tilfredshed for enhver dag.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 100,
    },
    {
      id: "cheese-greens-chebureky",
      title: {
        ua: "ЧЕБУРЕКИ З СИРОМ ТА ЗЕЛЕННЮ",
        dk: "CHEBUREKIS MED OST OG GRØNT",
      },
      description: {
        ua: 'Цей апетитний "снек" має неповторний смак і текстуру, а аромат зелені додає йому особливого шарму. Незалежно - сніданок, обід чи перекус, завжди задовольнять ваші смакові почуття.',
        dk: 'Denne appetitlige "snack" har en unik smag og tekstur, og duften af grønt giver den en særlig charme. Uanset om det er morgenmad, frokost eller mellemmåltid, vil dine smagsløg altid være tilfredse.',
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 100,
    },
    {
      id: "potatoes-varenyky",
      title: {
        ua: "ВАРЕНИКИ З КАРТОПЛЕЮ",
        dk: "DUMPLINGER MED KARTOFLER",
      },
      description: {
        ua: "Наші варенички, найкращі після маминих! Так багато начинки, що хочеться ще і ще.",
        dk: "Lækker hjemmelavede dumplings med kartoffelmos. En fremragende skål til hver dag eller begivenhed.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 90,
    },
    {
      id: "cabbage-varenyky",
      title: {
        ua: "ВАРЕНИКИ З КАПУСТОЮ",
        dk: "DUMPLINGER MED KÅL",
      },
      description: {
        ua: "Традиційна страва на свято, повсякдень, ну і звісно ж що до Різдва.",
        dk: "Er ofte forberedt til den hellige juleaften.Lækker ret med fyld - stuvet kål. Serveret med stegte løg eller bacon.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 90,
    },
    {
      id: "cherries-varenyky",
      title: {
        ua: "ВАРЕНИКИ З ВИШНЯМИ",
        dk: "DUMPLINGER MED KIRSEBÆR",
      },
      description: {
        ua: "Вареники з вишнями це поєднання соковитих вишень та майстерності наших кухарів.",
        dk: "Meget velsmagende og saftige dumplings med kirsebær. Spænd den daglige mad perfekt og forbedrer humøret markant",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 90,
    },
    {
      id: "kotleta-po-kyivsky",
      title: {
        ua: "КОТЛЕТА ПО-КИЇВСЬКИ",
        dk: "KIEV-STYLE COTLET",
      },
      description: {
        ua: "Це делікатесна страва, виготовлена з розпашеної курячої котлети, що містить у собі соковите начиння з вершкового масла та зелені. Цей смажений шедевр відрізняється ніжним м'ясом, хрусткою скоринкою і розплавленим смаком вершкового масла, яке просочує кожен шматочок котлетки.",
        dk: "Dette er en delikat ret lavet af en stegt kyllingebøf, der indeholder et saftigt fyld af smør og grønt. Dette stegte mesterværk er kendetegnet ved mørt kød, en sprød skorpe og den smeltede smag af smør, som gennemsyrer hvert stykke patty.",
      },
      unit: {
        ua: "ЗА 1 ШТ.",
        dk: "TIL 1 PC.",
      },
      price: 35,
    }
  ],
  desserts: [
    {
      id: "eclairs",
      title: {
        ua: "ЕКЛЕРИ З КРЕМ СИРОМ ТА ВЕРШКАМИ",
        dk: "ECLAIRS MED FREMEOST",
      },
      description: {
        ua: "Заварні тістечка з крем сиром та вершками подарують тобі легкий та вишуканий смак",
        dk: "Vanillecremekager med flødeost og fløde vil give dig en let og lækker smag. Stor dessert.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 200,
    },
    {
      id: "condensed-milk-tubes",
      title: {
        ua: "ТРУБОЧКИ ЗІ ЗГУЩЕНИМ МОЛОКОМ",
        dk: "SØDE RØR MED KONDENSERET MÆLK",
      },
      description: {
        ua: "Завжди хрусткі та повністю заповнені домашньою вареною згущенкою трубочки.",
        dk: "Altid sprøde og helt fyldt med hjemmelavede kogte kondensmælksrør.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 200,
    },
    {
      id: "condensed-milk-nuts",
      title: {
        ua: "ГОРІШКИ ЗІ ЗГУЩЕНИМ МОЛОКОМ",
        dk: "NØDDER MED KONDENSERET MÆLK",
      },
      description: {
        ua: "Горішки зі згущеним молоком, передають незабутній смак дитинства, як найяскравіше.",
        dk: "Nødder med kondenseret mælk formidler barndommens uforglemmelige smag som den lyseste.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 200,
    },
    {
      id: "honey-cake",
      title: {
        ua: "ТОРТ МЕДОВИК",
        dk: "HONNING LAGKAGE",
      },
      description: {
        ua: "Нотка меду додає ніжності смаку. Ми його називаємо - «найсмачніший спогад дитинства».",
        dk: 'Et strejf af honning tilføjer ømhed til smagen. Vi kalder det - "det lækreste barndomsminde".',
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 200,
    },
    {
      id: "napoleon-cake",
      title: {
        ua: "ТОРТ НАПОЛЕОН",
        dk: "NAPOLEON KAGE",
      },
      description: {
        ua: "Наш епічний торт наполеон. Але будьте обережними, бо дуже важко буде зупинитись на одному шматочку.",
        dk: "Din episke opgave er Napoleon. Men vær ærgelig, fordi det vil være tro svært så dvæle han ikke drikker.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 200,
    },
    {
      id: "cherry-biscuit",
      title: {
        ua: "БІСКВІТ З ВИШНЕЮ",
        dk: "LAGKAGE MED KIRSEBÆR",
      },
      description: {
        ua: "Додавання вишні у десерти - це найкраще, що вигадало людство. Чому? Бо це в будь-якому випадку дуже смачно!",
        dk: "At tilføje kirsebær til desserter er det bedste, menneskeheden nogensinde har opfundet.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 200,
    },
    {
      id: "cherry-bagels",
      title: {
        ua: "РОГАЛИКИ З ВИШНЕЮ",
        dk: "СROISSANT MED KIRSEBÆR",
      },
      description: {
        ua: "Смачні, неймовірно ароматні і дуже апетитні рогалики з вишнею, підкорять вас і ваших рідних з першого шматочка.",
        dk: "Lækre, utrolig duftende og meget appetitlige bagels med kirsebær vil fange dig og din familie fra det første stykke.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 100,
    },
    {
      id: "sweet-pies",
      title: {
        ua: "Пиріжки з солодкою начинкою",
        dk: "Tærter med sødt fyld",
      },
      description: {
        ua: `Начинка за домовленістю`,
        dk: `Påfyldning efter aftale`,
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 100,
    },
    // {
    //   id: "sweet-pies-0",
    //   title: {
    //     ua: "Пиріжки з сиром",
    //     dk: "Ostekager",
    //   },
    //   description: {
    //     ua: `Пиріжки з сиром`,
    //     dk: `Ostekager`,
    //   },
    //   unit: {
    //     ua: "ЗА 1 КГ.",
    //     dk: "PR 1 KG.",
    //   },
    //   price: 100,
    // },
    // {
    //   id: "sweet-pies-1",
    //   title: {
    //     ua: "Пиріжки з яблуками",
    //     dk: "Æbletærter",
    //   },
    //   description: {
    //     ua: `Пиріжки з яблуками`,
    //     dk: `Æbletærter`,
    //   },
    //   unit: {
    //     ua: "ЗА 1 КГ.",
    //     dk: "PR 1 KG.",
    //   },
    //   price: 100,
    // },
    // {
    //   id: "sweet-pies-2",
    //   title: {
    //     ua: "Пиріжки з будь-яким джемом або повидлом",
    //     dk: "Tærter med eventuelt syltetøj eller marmelade",
    //   },
    //   description: {
    //     ua: `Пиріжки з будь-яким джемом або повидлом`,
    //     dk: `Tærter med eventuelt syltetøj eller marmelade`,
    //   },
    //   unit: {
    //     ua: "ЗА 1 КГ.",
    //     dk: "PR 1 KG.",
    //   },
    //   price: 100,
    // },
    {
      id: "salty-pies",
      title: {
        ua: "Пиріжки з солоною начинкою",
        dk: "Tærter med salt fyld",
      },
      description: {
        ua: `Начинка за домовленістю`,
        dk: `Påfyldning efter aftale`,
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 100,
    },
    // {
    //   id: "salty-pies-0",
    //   title: {
    //     ua: "Пиріжки з м‘ясом",
    //     dk: "Kødtærter",
    //   },
    //   description: {
    //     ua: `Пиріжки з м‘ясом`,
    //     dk: `Kødtærter`,
    //   },
    //   unit: {
    //     ua: "ЗА 1 КГ.",
    //     dk: "PR 1 KG.",
    //   },
    //   price: 100,
    // },
    // {
    //   id: "salty-pies-1",
    //   title: {
    //     ua: "Пиріжки з гречкою та печінкою",
    //     dk: "Boghvede og leverpostej Meget velsmagende",
    //   },
    //   description: {
    //     ua: `Пиріжки з гречкою та печінкою`,
    //     dk: `Boghvede og leverpostej Meget velsmagende`,
    //   },
    //   unit: {
    //     ua: "ЗА 1 КГ.",
    //     dk: "PR 1 KG.",
    //   },
    //   price: 100,
    // },
    {
      id: "cherry-cake",
      title: {
        ua: "Торт Вишнева Гірка або Монастирська Хата",
        dk: "Vishnyeva Girka eller Monastyrska Khata kage",
      },
      description: {
        ua: "Цей торт - ідеальний вибір для тих, хто любить солодкі десерти з легкою кислинкою та ароматом вишні. Він підніме настрій і залишить незабутнє смакове враження. Вишнева гірка - це справжнє задоволення для смакових рецепторів.",
        dk: "Denne kage er et ideelt valg for dem, der kan lide søde desserter med en let surhed og aroma af kirsebær. Det vil løfte dit humør og efterlade et uforglemmeligt smagsindtryk. Kirsebærbitter er en sand fornøjelse for smagsløgene.",
      },
      unit: {
        ua: "ЗА 1 КГ.",
        dk: "PR 1 KG.",
      },
      price: 200,
    },
    // {
    //   id: "meringue-roll",
    //   title: {
    //     ua: "Меренговий Рулет",
    //     dk: "Marengsrulle",
    //   },
    //   description: {
    //     ua: "Меренговий рулет - це елегантний десерт, який поєднує в собі легкість меренги з багатством смаку. Ідеальний вибір для любителів легких, але смачних десертів, які вразять ваші смакові рецептори.",
    //     dk: "Marengsrulle er en elegant dessert, der kombinerer marengsens lethed med smagsrigdommen. Et ideelt valg for elskere af lette, men velsmagende desserter, der vil forbløffe dine smagsløg.",
    //   },
    //   unit: {
    //     ua: "ЗА 1 КГ.",
    //     dk: "PR 1 KG.",
    //   },
    //   price: 160,
    // }
  ],
  menus: [
    {
      id: "start-menu",
      title: {
        ua: "СТАРТОВЕ МЕНЮ",
        dk: "START MENU",
      },
      description: {
        ua: [`Грінки або брускети`, `Печінковий торт`, `Голубці`, `Салат з тунця (варена картопля, морква, яйця, тунець, сир, зелень, майонез)`, `Салат з крабових паличок`, `Млинці з м'ясом`, `Курячі відбивні з ананасом та сиром`],
        dk: [`Toasts eller bruschettas`, `Leverkage`, `Fyldt kål`, `Tunsalat (kogte kartofler, gulerødder, æg, tun, ost, grønt, mayonnaise)`, `Krabben salat (Ukrainsk)`, `Pandekager med kød`, `Кylling Koteletter med ananas og ost`],
      },
      unit: {
        ua: "З ПЕРСОНИ",
        dk: "PR.PERSON",
      },
      price: 210,
    },
    {
      id: "standard-menu",
      title: {
        ua: "СТАНДАРТНЕ МЕНЮ",
        dk: "STANDARD MENU",
      },
      description: {
        ua: [`Салат "Шуба"`, `Салат з тунця (варена картопля, морква, яйця, тунець, сир, зелень, майонез)`, `Печінковий торт`, `Млинці з м'ясом`, `Перець фарширований`, `Курячі крильця запечені`, `Лосось зі стручковою квасолею в соусі`, `Еклери з крем сиром та лососем`],
        dk: [`"Sild under en pelsfrakke" salat`, `Tunsalat (kogte kartofler, gulerødder, æg, tun, ost, grønt, mayonnaise)`, `Leverkage`, `Pandekager med kød`, `Fyldte peberfrugter`, `Kyllingevinger bages`, `Laks med bønner`, `Eclairs med ost og laks`],
      },
      unit: {
        ua: "З ПЕРСОНИ",
        dk: "PR.PERSON",
      },
      price: 220,
    },
    {
      id: "medium-menu",
      title: {
        ua: "МЕДІУМ МЕНЮ",
        dk: "MELLEM MENU",
      },
      description: {
        ua: [`Салат "Шуба"`, `Салат з тунця (варена картопля, морква, яйця, тунець, сир, зелень, майонез)`, `Печінковий торт`, `Млинці з м'ясом`, `Перець фарширований`, `Курячі крильця запечені`, `Лосось зі стручковою квасолею в соусі`, `Еклери з крем сиром та лососем`, `М'ясна тарілка`],
        dk: [`"Sild under en pelsfrakke" salat`, `Tunsalat (kogte kartofler, gulerødder, æg, tun, ost, grønt, mayonnaise)`, `Leverkage`, `Pandekager med kød`, `Fyldte peberfrugter`, `Kyllingevinger bages`, `Laks med bønner`, `Eclairs med ost og laks`, `Kød tallerken`],
      },
      unit: {
        ua: "З ПЕРСОНИ",
        dk: "PR.PERSON",
      },
      price: 240,
    },
    {
      id: "holiday-menu",
      title: {
        ua: "СВЯТКОВЕ МЕНЮ",
        dk: "FEST MENU",
      },
      description: {
        ua: [`Салат "Шуба"`, `Салат з тунця (варена картопля, морква, яйця, тунець, сир, зелень, майонез)`, `Салат з броколі`, `Млинці з м'ясом`, `Печінковий торт`, `Лосось зі стручковою квасолею в соусі`, `Курячі крильця запечені`, `М'ясна тарілка`, `Перець фарширований`, `Млинці з грибами та сиром`, `Рибна тарілка (лосось та креветки)`, `Еклери з крем сиром та лососем`],
        dk: [`"Sild under en pelsfrakke" salat`, `Tunsalat (kogte kartofler, gulerødder, æg, tun, ost, grønt, mayonnaise)`, `"Brocolisalat"`, `Pandekager med kød`, `Leverkage`, `Laks med bønner`, `Kyllingevinger bages`, `Kød tallerken`, `Fyldte peberfrugter`, `Pandekager med svampe + ost`, `Eclairs med ost og laks`],
      },
      unit: {
        ua: "З ПЕРСОНИ",
        dk: "PR.PERSON",
      },
      price: 290,
    },
    {
      id: "kings-menu",
      title: {
        ua: "КОРОЛІВСЬКЕ МЕНЮ",
        dk: "KONGELIG MENU",
      },
      description: {
        ua: [`Салат "Шуба"`, `Салат з тунця (варена картопля, морква, яйця, тунець, сир, зелень, майонез)`, `Салат з броколі`, `Млинці з м'ясом`, `Печінковий торт`, `Лосось зі стручковою квасолею в соусі`, `Курячі крильця запечені`, `М'ясна тарілка`, `Перець фарширований`, `Млинці з грибами та сиром`, `Еклери з крем сиром та лососем`, `Картопля у вершках`, `Асорті з млинців (мак, вишня, сир, ізюм)`, `Рибна тарілка (лосось та креветки)`],
        dk: [`"Sild under en pelsfrakke" salat`, `Tunsalat (kogte kartofler, gulerødder, æg, tun, ost, grønt, mayonnaise)`, `"Brocolisalat"`, `Pandekager med kød`, `Leverkage`, `Laks med bønner`, `Kyllingevinger bages`, `Kød tallerken`, `Fyldte peberfrugter`, `Pandekager med svampe + ost`, `Eclairs med ost og laks`, `Kartofler i fløde`, `Diverse pandekager`, `Fisketallerken (laks og rejer)`],
      },
      unit: {
        ua: "З ПЕРСОНИ",
        dk: "PR.PERSON",
      },
      price: 320,
    }
  ],
  additionalDishes: {
    title: {
      ua: "ДОДАТКОВІ СТРАВИ ДО МЕНЮ АБО НА ЗАМІНУ",
      dk: "YDERLIGERE RETTER TIL MENUEN ELLER TIL UDSKIFTNING",
    },
    description: {
      ua: [`Холодець`, `Олів'є`, `Капуста квашена`, `Капуста маринована з буряком`, `Баклажани з помідорами та часником`, `Тарталетки зі спаржею та куркою`, `Салат "Грузинський" (квасоля, перець болгарський, фіолетова цибуля маринована, варена яловичина, зелень + олія)`, `Салат "Морський" (рис, тунець, огірок, яйця, крабові палички, сир, майонез, зелень)`, `Картопляники з м'ясом (перекладені у формі з грибами та тушковані)`, `Курячі котлетки з грибним соусом`, `Мариновані шампіньйони (шампіньйони, болг. перець, морква)`, `Тушкована куряча печінка з цибулею та морквою`, `Млинці з гречкою та печінкою`, `Млинці з куркою та сиром`, `Млинці з грибами та сиром`, `Тушкована капуста з ізюмом або без`],
      dk: [`Aspic`, `Olivier salat`, `Sauerkraut`, `Syltet kål med rødbeder`, `Auberginer med tomater og hvidløg`, `Tarteletter med asparges og kylling`, `"Georgisk" salat (bønner, peberfrugt, syltede lilla løg, kogt oksekød, grønt + olie)`, `"Havs" salat (ris, tun, agurk, æg, krabbestænger, ost, mayonnaise, grønt)`, `Kartofler med kød (vendt i en form med svampe og stuvet)`, `Kyllingekoteletter med svampesauce`, `Syltede champignoner (svampe, peberfrugt, gulerødder)`, `Stuvet kyllingelever med løg og gulerødder`, `Pandekager med boghvede og lever`, `Pandekager med kylling og ost`, `Pandekager med svampe og ost`, `Stuvet kål med eller uden rosiner`],
    },
  }
}
