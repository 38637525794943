import { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { observer } from "mobx-react-lite"
import { useTranslation } from 'react-i18next'

import styles from './Cart.module.css'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Input from '../components/Input'
import ButtonUp from '../components/ButtonUp'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import { cartStore } from "../store/cartStore"
import { ReactComponent as CartIconDelete } from '../assets/shopping-cart-delete-icon.svg'
import { ReactComponent as CartIconMinus } from '../assets/shopping-cart-minus-icon.svg'

const CartItem = observer((props) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const { cartItem, index } = props;
  const { title, count, totalPrice } = cartItem;
  const handleMinus = () => {
    cartStore.incCount(index, -1);
  };
  const handlePlus = () => {
    cartStore.incCount(index, +1);
  };
  const handleRemoveItem = () => {
    cartStore.removeItem(index)
  }

  return (
    <div className={styles.dish}>
      <div onClick={handleRemoveItem} className={styles.dishIconContainer}><CartIconMinus/></div>
      <h4 className={styles.dishTitle}>{title[lang]}</h4>
      <div>
        <div className={styles.counterContainer}>
          <button onClick={handleMinus}>-</button>
          <p className={styles.dishCounter}>{count}</p>
          <button onClick={handlePlus}>+</button>
        </div>
        <p className={styles.dishPrice}><span>{totalPrice}</span> DKK</p>
      </div>
    </div>
  )
});

const isEmptyStr = (x) => {
  return typeof x === 'string' && x.trim() === '';
}

const clearCart = () => cartStore.clearItems()

const Cart = observer(() => {
  const { t } = useTranslation();
  const [finished, setFinished] = useState(false);
  const { name, phone } = cartStore;
  const { cartItems, totalPrice } = cartStore;
  const onNameChange = (name) => cartStore.setName(name);
  const onPhoneChange = (phone) => cartStore.setPhone(phone);
  const [error, setError] = useState(null);
  const onSendClick = () => {
    const data = { name, phone, dishes: cartItems };
    const empty = Object.values(data).find(isEmptyStr) != null;
    if (empty) {
      setError('empty');
      return;
    }
    setError('loading');
    fetch('/api/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(res => {
      if (!res.ok) {
        // some error logic
        setError('server');
      }
      else {
        // some success logic
        clearCart()
        setFinished(true);
      }
    }).catch(() => setError('server'));
  };
  if (finished)
    return (
      <Navigate to="/thankyou" />
    );
  const isDisabled = cartStore.totalItemsCount === 0 ? 1 : 0
  const clearButtonStyle = cartStore.totalItemsCount > 0 ? styles.clearButton : styles.clearButtonHide
  return (
    <div className={styles.cartPage}>
      <ScrollToTopOnMount />
      <Navbar />
      <div className={styles.cartContainer}>
        <div className={styles.cart}>
          <div className={styles.dishesContainer}>
            {
              cartItems.map((item, index) => {
                return (
                  <CartItem key={item.id} cartItem={item} index={index} />
                )
              })
            }
          </div>
          <p className={styles.finalSum}>
            {t('cart-total')} <br/>
            <span><span>{totalPrice}</span> DKK</span>
          </p>
          <p className={styles.shippingInfo}>{t('cart-shipping-info-1')} - 1 DKK.<br/>{t('cart-shipping-info-2')}</p>
          {error === 'loading' && <p className={styles.loadingStyle}>{t('contacts-loading-message')}</p>}
          {error === 'empty' && <p className={styles.errorStyle}>{t('contacts-empty-message')}</p>}
          {error === 'server' && <p className={styles.errorStyle}>{t('contacts-error-message')}</p>}
          <p className={styles.userInfo}>{t('cart-prompt')}</p>
          <Input value={name}
                 onChange={onNameChange}
                 placeholder={t('cart-name')} 
                 disabled={isDisabled}/>
          <Input value={phone}
                 onChange={onPhoneChange}
                 placeholder={t('cart-phone-number')} 
                 disabled={isDisabled}/>
          <button onClick={onSendClick} className={styles.orderButton} disabled={isDisabled}>
            {t('cart-button')}
          </button>
          <div onClick={clearCart} className={clearButtonStyle}><CartIconDelete/><span>{t('cart-clear-button')}</span></div>
        </div>
      </div>
      <ButtonUp/>
      <Footer/>
    </div>
  );
});

export default Cart;
