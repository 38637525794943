import Navbar from '../components/Navbar'
import Menu from '../components/Menu'
import Footer from '../components/Footer'
import styles from './Menus.module.css'
import { ReactComponent as HeartIcon } from '../assets/heart-icon.svg'
import ButtonUp from '../components/ButtonUp'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import { dishes } from '../store/dishes';
import { useTranslation } from 'react-i18next'

function Menus() {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <div className={styles.pageContainer}>
        <ScrollToTopOnMount/>
        <Navbar/>
        <div className={styles.header}>
          <h1 className={styles.menusHeading}>{t('menus-header-title-1')} <span>{t('menus-header-title-2')}</span> {t('menus-header-title-3')}</h1>
          <p>{t('menus-header-description')}</p>
        </div>
        <div className={styles.groupContainer}>
          <p className={styles.slogan}>{t('dishes-slogan')}</p>
          <HeartIcon className={styles.heartIcon} fill='#daa236' width="40px" height="40px"/>
          <h2 className={styles.groupTitle}>{t('menus-title')}</h2>
        </div>
        <div className={styles.menus}>
          {dishes.menus.map(menu => {
            return (
              <Menu key={menu.id} menu={menu} />
            )
          })}
        </div>
        <div className={styles.additionalContainer}>
          <h2>{dishes.additionalDishes.title[lang]}</h2>
          <div className={styles.additionalDishes}>
            {dishes.additionalDishes.description[lang].map(dish => {
              return(
                <div>{dish}</div>
              )
            })}
          </div>
        </div>
        <ButtonUp/>
        <Footer/>
    </div>
  )
}

export default Menus
