import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import styles from '../pages/About.module.css'
import { useTranslation } from 'react-i18next'
import { ReactComponent as HeartIcon } from '../assets/heart-icon.svg'
import { ReactComponent as HandshakeIcon } from '../assets/handshake-icon.svg'
import { ReactComponent as CutleryIcon } from '../assets/cutlery-icon.svg'
import ButtonUp from '../components/ButtonUp'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'

function About() {
  const { t } = useTranslation()
  
  return (
    <div className="About">
      <ScrollToTopOnMount/>
      <Navbar/>
      <div className={styles.pageContainer}>
        <h1>{t('about-history-title')}</h1>
        <HeartIcon className={styles.heartIcon}/>
        <h3>{t('about-appearance')}</h3>
        <div className={styles.ownerContainer}>
          <img src={require('../assets/valentyna-about.jpg')} alt="owner"/>
          <p>{t('about-history')}</p>
        </div>
        <HandshakeIcon/>
        <p className={styles.team}>{t('about-team')}</p>
        <div className={styles.rulesContainer}>
          <p>{t('about-rules')}</p>
          <div className={styles.rule}>
            <h4>{t('about-rule-title-1')}</h4>
            <p>{t('about-rule-description-1')}</p>
          </div>
          <div className={styles.rule}>
            <h4>{t('about-rule-title-2')}</h4>
            <p>{t('about-rule-description-2')}</p>
          </div>
          <div className={styles.rule}>
            <h4>{t('about-rule-title-3')}</h4>
            <p>{t('about-rule-description-3')}</p>
          </div>
        </div>
        <div className={styles.slogan}>
          <CutleryIcon/>
          <p>{t('about-slogan')}</p>
        </div>
      </div>
      <ButtonUp/>
      <Footer/>
    </div>
  )
}

export default About
