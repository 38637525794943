import { Link } from 'react-router-dom'
import styles from './Footer.module.css'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PhoneIcon } from '../assets/phone-icon.svg'
import { ReactComponent as AdressIcon } from '../assets/adress-icon.svg'
import { ReactComponent as FacebookIcon } from '../assets/facebook-icon.svg'

function Footer() {
    const { t } = useTranslation()
    const year = new Date().getFullYear();
    return (
        <div className={styles.footer}>
            <div className={styles.contactsInfo}>
                <div className={styles.phoneContainer}>
                    <PhoneIcon fill='#f0ab26' width="46px" height="46px"/>
                    <p>{t('footer-phone-number')}<br/>
                        <a href="tel:+4550319318">+45 50 31 93 18</a>
                    </p>
                </div>
                <div className={styles.adressContainer}>
                    <AdressIcon fill='#f0ab26' width="46px" height="46px"/>
                    <p>{t('footer-adress')}<br/>
                        <a href="https://www.google.com/maps/place/Pilev%C3%A6nget+35,+8990+F%C3%A5rup,+Denmark/@56.5457206,9.8506701,17z/data=!3m1!4b1!4m6!3m5!1s0x464eaa89c671a50b:0x1c3e57782511cd53!8m2!3d56.5457206!4d9.8506701!16s%2Fg%2F11kq0c3gfz?entry=ttu">8990 Pilevænget 35, Fårup, Danmark</a>
                    </p>
                </div>
                <div className={styles.facebookContainer}>
                    <FacebookIcon fill='#f0ab26' width="46px" height="46px"/>
                    <p>Facebook:<br/>
                        <a href="https://www.facebook.com/valentina.tregybenko">Middag På Ukrainsk</a>
                    </p>
                </div>
            </div>
            <div className={styles.technicalInfo}>
                <div className={styles.technicalLinks}>
                    <div className={styles.logo}>
                        <Link to="/">
                            <img src={require("../assets/logo.png")} alt="logo"/>
                        </Link>
                    </div>
                    <div className={styles.technicalPages}>
                        <Link to="/privacy">{t('footer-privacy-policy')}</Link>
                        <span> ⋅ </span>
                        <Link to="/cookies">{t('footer-cookies')}</Link>
                    </div>
                    <div>
                        <Link to="/report">
                            <img src={require("../assets/control-report.jpg")} alt="control report"/>
                        </Link>
                    </div>
                </div>
                <p className={styles.copyright}>© {year} ⋅ Middag På Ukrainsk ⋅ {t('footer-rights')}</p>
            </div>
        </div>
    )
  }

  export default Footer
