import styles from './MessengerButton.module.css'
import { ReactComponent as MessengerIcon } from '../assets/messenger-icon.svg'

function MessengerButton(props) {
  const link = 'https://m.me/valentina.tregybenko';
  return (
    <a href={link} target="_blank"
       className={styles.messengerIconContainer}>
      <MessengerIcon />
    </a>
  )
}

export default MessengerButton;
