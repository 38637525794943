import { React, useState, useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styles from './Navbar.module.css'
import { ReactComponent as ToggleLangIcon } from '../assets/lang-toggle-icon.svg'
// import { ReactComponent as CartIcon } from '../assets/cart-icon.svg'
import { ReactComponent as CartIcon } from '../assets/shopping-cart-icon.svg'
import { ReactComponent as CartIconFull } from '../assets/shopping-cart-full-icon.svg'
import { ReactComponent as BurgerMenuIcon } from '../assets/burger-menu-icon.svg'
import { cartStore } from "../store/cartStore"
import { observer } from "mobx-react-lite"
import MessengerButton from './MessengerButton'

const NavLinks = (props) => {
  const { t } = useTranslation()
  const classNameFunc = ({ isActive }) => (isActive ? styles.active : '');
  return (
    <div className={props.className}>
      <NavLink to="/" className={classNameFunc}>{t('navbar-main')}</NavLink>
      <NavLink to="/dishes" className={classNameFunc}>{t('navbar-dishes')}</NavLink>
      <NavLink to="/menus" className={classNameFunc}>{t('navbar-menus')}</NavLink>
      <NavLink to="/about" className={classNameFunc}>{t('navbar-about')}</NavLink>
      <NavLink to="/contacts" className={classNameFunc}>{t('navbar-contacts')}</NavLink>
    </div>
  )
}

const useOutsideClick = (callback) => {
  const ref = useRef()

  useEffect(() => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback()
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  return ref
}

const LangButton = () => {
  const { i18n } = useTranslation()
  const langs = ['ua', 'dk']
  const [isActiveUa, setActiveUa] = useState(i18n.language === langs[0])
  const [isActiveDk, setActiveDk] = useState(i18n.language === langs[1])
  // const dropdownClassName = isActive ? styles.dropdownContent : styles.hideDropdown
  // const langButtonClassName = isActive ? styles.langButtonActive : styles.langButton
  // const handleClickOutside = () => {
  //   setActive(false)
  // }
  // const ref = useOutsideClick(handleClickOutside)
  // const toggleLang = () => {
  //   setActive(isActive => !isActive)
  // }
  // const newLang = i18n.language === langs[0] ? langs[1] : langs[0];
  const changeLang = (newLang) => {
    i18n.changeLanguage(newLang);
    localStorage.setItem('lang', newLang);
  }
  const setUa = () => {
    changeLang(langs[0])
    setActiveUa(true)
    setActiveDk(false)
  }
  const setDk = () => {
    changeLang(langs[1])
    setActiveDk(true)
    setActiveUa(false)
  }
  const langUaClassName = isActiveUa ? styles.langActive : styles.langOption
  const langDkClassName = isActiveDk ? styles.langActive : styles.langOption
  return (
    <div className={styles.lang}>
      <div className={styles.langButton}>
        <div className={styles.langIconContainer}>
          <ToggleLangIcon fill='black'/>
        </div>
        <div onClick={setUa} className={langUaClassName}>{langs[0]}</div>
        <span>.</span>
        <div onClick={setDk} className={langDkClassName}>{langs[1]}</div>
      </div>
      {/* <ul className={dropdownClassName}>
        <li onClick={changeLang}>{newLang}</li>
      </ul> */}
    </div>
  )
}

const BurgerMenuButton = () => {
  const [isActive, setActive] = useState(false)
  const sideMenuClassName = isActive ? styles.sideMenuContent : styles.hideSideMenu
  const burgerButtonClassName = isActive ? styles.burgerButtonActive : styles.burgerButton
  const handleClickOutside = () => {
    setActive(false)
  }
  const ref = useOutsideClick(handleClickOutside)
  const toggleBurger = () => {
    setActive(isActive => !isActive)
  }
  return (
    <div ref={ref} className={burgerButtonClassName}>
      <BurgerMenuIcon onClick={toggleBurger}/>
      <div className={sideMenuClassName}>
        <LangButton/>
        <NavLinks className={styles.sideMenu}/>
      </div>
    </div>
  )
}

const Navbar = observer((props) => {
  const cartCounter = cartStore.totalItemsCount > 0 ? styles.showCartCounter : styles.hideCartCounter
  const cartStyle = ({ isActive }) => (isActive ? styles.activeCartButton : styles.cartButton)
  return (
    <nav className={styles.navbar}>
        <div className={styles.logo}><NavLink to="/">Middag På Ukrainsk</NavLink></div>
        <NavLinks className={styles.pages}/>
        <div className={styles.navbarButtons}>
          <LangButton/>
          <NavLink to="/cart" className={cartStyle}>
              {cartStore.totalItemsCount > 0 ? <CartIconFull/> : <CartIcon/>}
              <span className={cartCounter}>{cartStore.totalItemsCount}</span>
          </NavLink>
          <MessengerButton/>
          <BurgerMenuButton/>
        </div>
    </nav>
  )
})

export default Navbar
