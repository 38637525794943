import { useEffect, useState } from 'react'
import styles from './ButtonUp.module.css'
import { ReactComponent as ArrowUp } from '../assets/arrow-up.svg'

function ButtonUp(props) {
  const [scrollTop, setScrollTop] = useState(0);
  const scrollOnClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  useEffect(() => {
    const handleScroll = event => {
      setScrollTop(window.scrollY)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, []);
  const buttonUpClassName = scrollTop !== 0 ? styles.button : styles.hideButton
  return (
    <div className={buttonUpClassName} onClick={scrollOnClick}>
      <ArrowUp />
    </div>
  );
}

export default ButtonUp
