import styles from './Menu.module.css'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite';
import { cartStore } from '../store/cartStore';
import { ReactComponent as CartIconAdd } from '../assets/shopping-cart-add-icon.svg'
import { ReactComponent as CartIconAdded } from '../assets/shopping-cart-added-icon.svg'

const Menu = observer((props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const { menu } = props;
  const { id, title, price, unit } = menu;
  const list = menu.description;
  const imgSrc = require('../assets/' + id + '.jpg');
  const isActive = cartStore.hasItem(id);
  const onOrderClick = () => {
    cartStore.addItem(id, title, price);
  };
  const isPressed = isActive ? styles.pressedButton : styles.orderButton
  const buttonText = isActive ? t('dish-button-pressed') : t('dish-button')

  return (
    <div className={styles.menuContainer}>
      <img src={imgSrc} width="100%" alt={title[lang]} />
      <h4 className={styles.menuTitle}>{title[lang]}</h4>
      <ul className={styles.dishesList}>
        {list[lang].map((dish, ind) => {
          return (
            <li key={ind}>{dish}</li>
          )
        })}
      </ul>
      <div className={styles.priceOrderContainer}>
        <p className={styles.menuPriceContainer}>
        {t('dish-price')} <span className={styles.menuPrice}>{price} DKK.</span> {unit[lang]}
        </p>
        <div className={isPressed} onClick={onOrderClick}>{isActive ? <CartIconAdded/> : <CartIconAdd/>}<span>{buttonText}</span></div>
      </div>
    </div>
  )
})

export default Menu
