import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from './resources/resources.js'

const lang = localStorage.getItem('lang') || 'dk';
localStorage.setItem('lang', lang);
i18n.use(initReactI18next)
    .init({
      resources,
      lng: lang,
    });

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
