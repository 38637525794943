import { useTranslation } from 'react-i18next'

import styles from './ThankYouPage.module.css'
import boxImg from '../assets/check-white.png'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import ButtonUp from '../components/ButtonUp'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'

const ThankYouPage = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.container}>
        <ScrollToTopOnMount/>
        <Navbar/>
        <div className={styles.message}>
          <div>
            <img src={boxImg} alt='checker'/>
            <h1>{t('order-success-1')}</h1>
            <p>{t('order-success-2')}</p>
          </div>
        </div>
        <ButtonUp/>
        <Footer/>
    </div>
  );
};

export default ThankYouPage;
