import styles from './Dish.module.css'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite';
import { cartStore } from '../store/cartStore';
import { ReactComponent as CartIconAdd } from '../assets/shopping-cart-add-icon.svg'
import { ReactComponent as CartIconAdded } from '../assets/shopping-cart-added-icon.svg'

const Dish = observer((props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const { dish } = props;
  const { id, title, description, price, unit } = dish;
  const imgSrc = require('../assets/' + id + '.jpg');
  const isActive = cartStore.hasItem(id);
  const onOrderClick = () => {
    cartStore.addItem(id, title, price);
  };
  const isPressed = isActive ? styles.pressedButton : styles.orderButton
  const buttonText = isActive ? t('dish-button-pressed') : t('dish-button')

  return (
    <div className={styles.dish}>
        <img src={imgSrc} width="100%" alt={title[lang]}/>
        <h4 className={styles.dishTitle}>{title[lang]}</h4>
        <p className={styles.dishDescription}>{description[lang]}</p>
        <div className={styles.priceOrderContainer}>
          <p className={styles.dishPriceContainer}>
            {t('dish-price')} <span className={styles.dishPrice}>{price} DKK.</span> {unit[lang]}
          </p>
          <div className={isPressed} onClick={onOrderClick}>{isActive ? <CartIconAdded/> : <CartIconAdd/>}<span>{buttonText}</span></div>
        </div>
    </div>
  )
});

export default Dish
